import { NgModule } from "@angular/core";
import { RouteReuseStrategy } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NativePageTransitions } from "@ionic-native/native-page-transitions/ngx";
import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications/ngx";
import { FirebaseMessaging } from "@awesome-cordova-plugins/firebase-messaging/ngx";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { Badge } from "@ionic-native/badge/ngx";

// Core Modules
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { PushNotificationsService } from "./core/fcm.service";
import { CustomMaterialModule } from "./core/material.module";
import { SkeletonModule } from "./core/skeleton.module";
import { CoreModule } from "./core/core.module";

// Third party library modules
import { ChartsModule } from "ng2-charts";
import {
    LazyLoadImageModule,
    LAZYLOAD_IMAGE_HOOKS,
    ScrollHooks,
} from "ng-lazyload-image";

// Firebase
import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { LoginComponent } from "./auth/login/login.component";
import { AppComponent } from "./app.component";

@NgModule({
    declarations: [AppComponent, LoginComponent],
    imports: [
        CoreModule,
        ChartsModule,
        SkeletonModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        CustomMaterialModule,
        MatMomentDateModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        IonicModule.forRoot({
            scrollPadding: false,
            scrollAssist: false,
        }),
        AppRoutingModule,
    ],
    providers: [
        Badge,
        SplashScreen,
        DecimalPipe,
        CurrencyPipe,
        FingerprintAIO,
        FirebaseMessaging,
        LocalNotifications,
        NativePageTransitions,
        PushNotificationsService,
        { provide: MAT_DATE_LOCALE, useValue: "he" },
        { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
