import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

import { User } from 'src/app/models/user';

import VanillaTilt from 'vanilla-tilt';

@Component({
  selector: 'glassmorphism-card',
  templateUrl: './glassmorphism-card.component.html',
  styleUrls: ['./glassmorphism-card.component.scss']
})
export class GlassmorphismCardComponent implements OnInit, OnDestroy {

  private elemRef: HTMLElement;
  private destroySub = new Subject<void>();

  @Input() user: User;
  @Input() userEmail: string;

  constructor(
    private platform: Platform
  ) { }

  isMobileDevice(): boolean {
    return !this.platform.is('desktop') && !document.URL.startsWith('http');
  }

  setGlareEffect(): void {
    if (!this.isMobileDevice()) {
      this.elemRef = document.querySelector('glassmorphism-card > .glassmorphism-card__card');
      VanillaTilt.init(this.elemRef, {
        max: 10,
        glare: true,
        "max-glare": .3,
      });
    }
  }

  ngOnInit(): void {
    this.setGlareEffect();
  }

  ngOnDestroy() {
    this.destroySub.next();
    this.destroySub.complete();
    this.elemRef['vanillaTilt']?.destroy();
  }
}
