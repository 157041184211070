export class FixedCharge {
    chargeId: string;
    chargeDate: Date | string;
    chargeType: ChargeType | string;
    chargeAmount: number;
    trxAmount?: number;
    isPermanent?: boolean;
    permanentFrequency?: string;
}

export enum ChargeType {
    CREDIT_CARD = 'כרטיס אשראי',
    BANK_ACCOUNT = 'חשבון בנק',
}