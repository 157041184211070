import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";

@Injectable({
    providedIn: 'root',
})
export class AlertsService {

    constructor(
        private alertCtrl: AlertController
    ) { }

    async message(header: any, message?: any) {
        const alert = await this.alertCtrl.create({
            header: header,
            message: message ?? '',
            buttons: ['סגור']
        });

        await alert.present();
    }

    async error(error: any) {
        const alert = await this.alertCtrl.create({
            header: 'התרחשה שגיאה',
            message: error,
            buttons: ['סגור']
        });

        await alert.present();
    }
}