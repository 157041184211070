import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import * as moment from 'moment/moment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen
  ) {}

  ngOnInit(): void {
    moment.locale("he");
    this.initializeApp();
  }

  private initializeApp() {
    if (this.isMobileDevice()) {
      this.platform.ready().then(() => {
        this.splashScreen.hide();
      });
    }
  }

  isMobileDevice(): boolean {
    return !this.platform.is('desktop') && !document.URL.startsWith('http');
  }
}