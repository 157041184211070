import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { catchError, first, map, shareReplay, tap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

import { User } from '../models/user';
import { Deposit } from '../models/deposit';
import { CreditCard } from '../models/credit-card';
import { BankAccount } from '../models/bank-account';
import { FixedCharge } from '../models/fixed-charge';
import { DepositActivity } from '../models/deposit-activity';
import { LocalStorageKey, LocalStorageService } from './local-storage-service';
import { AlertsService } from './alerts.service';

import * as moment from 'moment/moment';

@Injectable({
    providedIn: "root",
})
export class DatabaseService {
    constructor(
        private db: AngularFirestore,
        private alerts: AlertsService,
        private localStorageService: LocalStorageService
    ) {}
    
    // runQuery(): void {
    //     const search = 'הלוואה - הפניקס פנסיה';
    //     this.db
    //         .collection(`fixed-charges`, (ref) =>
    //             ref.orderBy("chargeId").startAt(search).endAt(search + "\uf8ff")
    //         )
    //         .get()
    //         .pipe(
    //             first(),
    //             map((res) =>
    //                 res.docs.map((doc) => {
    //                     doc.ref.update({
    //                         chargeAmount: 823.78,
    //                     });
    //                     return Object.assign(new FixedCharge(), doc.data());
    //                 })
    //             ),
    //             tap((res) => console.log(res))
    //         )
    //         .subscribe();
    // }

    registerFcmToken(token: string): Observable<any> {
        return from(
            this.db
                .collection(`users`)
                .doc(this.localStorageService.get(LocalStorageKey.USER_ID))
                .update({
                    fcmToken: token,
                })
        ).pipe(catchError((error) => this.alerts.error(error)));
    }

    getUser(userId: string): Observable<User> {
        return this.db
            .collection(`users`)
            .doc(userId)
            .get()
            .pipe(
                map((doc) =>
                    Object.assign(new User(), doc.data(), { userId: doc.id })
                ),
                catchError((err) => {
                    this.alerts.error(err);
                    return of(undefined);
                }),
                shareReplay()
            );
    }

    getBankAccount(): Observable<BankAccount> {
        return this.db
            .collection(`bank-account`)
            .get()
            .pipe(
                map((accounts) =>
                    accounts.docs.map((doc) => {
                        return Object.assign(new BankAccount(), doc.data(), {
                            accountId: doc.id,
                        });
                    })
                ),
                map((accounts) => accounts[0]),
                catchError((err) => {
                    this.alerts.error(err);
                    return of(undefined);
                }),
                shareReplay()
            );
    }

    getDeposits(): Observable<Deposit[]> {
        return this.db
            .collection(`deposits`)
            .get()
            .pipe(
                map((deposits) =>
                    deposits.docs.map((doc) => {
                        return Object.assign(new Deposit(), doc.data(), {
                            depositId: doc.id,
                        });
                    })
                ),
                catchError((err) => {
                    this.alerts.error(err);
                    return of([]);
                }),
                shareReplay()
            );
    }

    getDepositActivites(depositId: string): Observable<DepositActivity[]> {
        return this.db
            .collection(`deposit-activites`, (ref) =>
                ref.where("depositId", "==", depositId)
            )
            .get()
            .pipe(
                map((activites) =>
                    activites.docs.map((doc) => {
                        return Object.assign(new DepositActivity(), doc.data());
                    })
                ),
                catchError((err) => {
                    this.alerts.error(err);
                    return of([]);
                }),
                shareReplay()
            );
    }

    getDepositTotalActivites(
        deposits: string[]
    ): Observable<DepositActivity[]> {
        return this.db
            .collection(`deposit-activites`, (ref) =>
                ref.where("depositId", "in", deposits)
            )
            .get()
            .pipe(
                map((activites) =>
                    activites.docs.map((doc) => {
                        return Object.assign(new DepositActivity(), doc.data());
                    })
                ),
                catchError((err) => {
                    this.alerts.error(err);
                    return of([]);
                }),
                shareReplay()
            );
    }

    getCreditCards(): Observable<CreditCard[]> {
        return this.db
            .collection(`credit-cards`)
            .get()
            .pipe(
                map((cards) =>
                    cards.docs.map((doc) => {
                        return Object.assign(new CreditCard(), doc.data(), {
                            cardId: doc.id,
                        });
                    })
                ),
                catchError((err) => {
                    this.alerts.error(err);
                    return of([]);
                }),
                shareReplay()
            );
    }

    getMonthlyFixedCharges(): Observable<FixedCharge[]> {
        return this.db
            .collection(`fixed-charges`)
            .get()
            .pipe(
                map((charges) =>
                    charges.docs.map((doc) => {
                        return Object.assign(new FixedCharge(), doc.data(), {
                            chargeId: doc.id,
                        });
                    })
                ),
                tap((charges) =>
                    this.removeElapsedFixedCharges(
                        charges.filter(
                            (charge) =>
                                !charge.isPermanent &&
                                this.isElapsedFixedCharge(charge)
                        )
                    )
                ),
                map((charges) =>
                    charges.filter((charge) => this.isNextMonthCharge(charge))
                ),
                catchError((err) => {
                    this.alerts.error(err);
                    return of([]);
                }),
                shareReplay()
            );
    }

    getAllFCMTokens(): Observable<string[]> {
        return this.db
            .collection(`users`)
            .get()
            .pipe(
                map((users) =>
                    users.docs.map((doc) => {
                        const data: any = doc.data();
                        return data?.fcmToken;
                    })
                ),
                catchError((err) => {
                    this.alerts.error(err);
                    return of(undefined);
                }),
                shareReplay()
            );
    }

    putFixedCharge(fixedCharge: FixedCharge): Observable<any> {
        return from(
            this.db
                .collection(`fixed-charges`)
                .doc(fixedCharge.chargeId)
                .set({ ...fixedCharge })
        ).pipe(catchError((error) => this.alerts.error(error)));
    }

    putDepositActivity(depositActivity: DepositActivity): Observable<any> {
        return from(
            this.db.collection(`deposit-activites`).add({ ...depositActivity })
        ).pipe(catchError((error) => this.alerts.error(error)));
    }

    updateDeposit(deposit: Deposit): Observable<any> {
        return from(
            this.db.collection(`deposits`).doc(deposit.depositId).update({
                depositAmount: deposit.depositAmount,
            })
        ).pipe(catchError((error) => this.alerts.error(error)));
    }

    updateBankAccount(bankAccount: BankAccount): Observable<any> {
        return from(
            this.db
                .collection(`bank-account`)
                .doc(bankAccount.accountId)
                .update({
                    balance: bankAccount.balance,
                    salary1: bankAccount.salary1,
                    salary2: bankAccount.salary2,
                    salary3: bankAccount.salary3,
                })
        ).pipe(catchError((error) => this.alerts.error(error)));
    }

    updateCreditCard(creditCard: CreditCard): Observable<any> {
        return from(
            this.db.collection(`credit-cards`).doc(creditCard.cardId).update({
                chargeAmount: creditCard.chargeAmount,
            })
        ).pipe(catchError((error) => this.alerts.error(error)));
    }

    private isElapsedFixedCharge(charge: FixedCharge): boolean {
        return moment(charge.chargeDate).diff(moment(), "days") < 0;
    }

    private removeElapsedFixedCharges(fixedCharges: FixedCharge[]): void {
        fixedCharges.forEach((fixedCharge) =>
            this.db
                .collection(`fixed-charges`)
                .doc(fixedCharge.chargeId)
                .delete()
        );
    }

    private isNextMonthCharge(charge: FixedCharge): boolean {
        if (charge.isPermanent) {
            switch (charge.permanentFrequency) {
                case "חיוב חודשי קבוע":
                    return true;
                case "דו חודשי - חודש זוגי":
                    return moment().month() % 2 === 0;
                case "דו חודשי - חודש אי זוגי":
                    return moment().month() % 2 !== 0;
                default:
                    return true;
            }
        } else {
            const nextMonth = moment().add(1, "months").toDate();
            const chargeDate = moment(charge.chargeDate).toDate();
            return moment(nextMonth).isSame(chargeDate, "month");
        }
    }
}