import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {

  constructor() { }

  @Input()
  size: 'sm' | 'bg' = 'bg';

  generateNArray(n: number): number[] {
    let arr: number[] = [];
    for (let i = 0; i < n; i++) {
      arr.push(i);
    }

    return arr;
  }

  trackingFn(index: number, item: number) {
    return item;
  }
}