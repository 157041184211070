import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Color, BaseChartDirective, Label, PluginServiceGlobalRegistrationAndOptions, MultiDataSet } from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';
import { Platform } from '@ionic/angular';

import { FixedCharge } from 'src/app/models/fixed-charge';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface PeriodicCharge {
    chargeName: string;
    currentChargeNumber: number;
    currentChargeAmount: number;
    totalCharges: number;
    trxAmount?: number;
}

@Component({
    selector: 'periodic-charge-table',
    styleUrls: ['./periodic-charge-table.component.scss'],
    templateUrl: './periodic-charge-table.component.html'
})
export class PeriodicChargeTableComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    @Input()
    charge: FixedCharge;

    @Input()
    load: BehaviorSubject<boolean>;

    inView: boolean;
    periodicCharge: PeriodicCharge;

    doughnutChartLabels: Label[] = [];
    doughnutChartData: MultiDataSet = [[]];
    doughnutChartType: ChartType = 'doughnut';
    doughnutChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 2000
        },
        cutoutPercentage: 80,
        tooltips: {
            enabled: false
        },
        hover: {
            mode: null
        },
    };

    doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{}];
    doughnutChartColors: Color[] = [{ backgroundColor: [] }];

    countUpOptions = {
        decimalPlaces: 2,
        duration: 1,
        useGrouping: true,
        useEasing: true,
        prefix: '₪'
    };

    constructor(
        private platform: Platform
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.load?.currentValue) {
            this.load.pipe(
                filter(value => value),
            ).subscribe(() => {
                this.inView = true;
                this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
            });
        }
    }

    isMobileDevice(): boolean {
        return true; //!this.platform.is('desktop') && !document.URL.startsWith('http');
    }

    private getPeriodicCharge(): PeriodicCharge {
        const chargeId = this.charge.chargeId.split(/\((\d+)\\(\d+)\)/gm);
        return {
            chargeName: chargeId[0]?.trim(),
            totalCharges: +chargeId[2],
            currentChargeNumber: +chargeId[1],
            currentChargeAmount: this.charge.chargeAmount,
            trxAmount: this.charge.trxAmount 
        } as PeriodicCharge;
    }

    private thisAsThat(callBack: Function) {
        const self = this;
        return function () {
            return callBack.apply(self, [this].concat(Array.prototype.slice.call(arguments)));
        };
    }

    ngOnInit(): void {
        this.periodicCharge = this.getPeriodicCharge();
        this.doughnutChartData[0] = [this.periodicCharge.currentChargeNumber, this.periodicCharge.totalCharges - this.periodicCharge.currentChargeNumber];
        this.doughnutChartColors[0].backgroundColor = ['rgb(166,94,255)', 'rgb(166,94,255,.3)'];
        this.doughnutChartPlugins[0].beforeDraw = this.thisAsThat((that: any, chart: any) => {
            const ctx = chart.ctx;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

            ctx.font = '700 1rem "Rubik", sans-serif';
            ctx.fillStyle = '#616e7e';
            ctx.fillText(`${this.periodicCharge.currentChargeNumber}/${this.periodicCharge.totalCharges}`, centerX, centerY);

            chart.legend.top = 310;
            chart.legend.bottom = 0;
        });
    }

    ngOnDestroy() {
        this.load?.unsubscribe();
    }
}