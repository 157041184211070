import { Injectable } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { forkJoin, from, Observable } from "rxjs";
import { filter, first, map, switchMap, tap } from "rxjs/operators";

import { Platform } from "@ionic/angular";
import { Badge } from "@ionic-native/badge/ngx";
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications/ngx";

import { AlertsService } from "./alerts.service";
import { DatabaseService } from "./database.service";
import { LocalStorageKey, LocalStorageService } from "./local-storage-service";
import { PushNotificationType } from "../models/push-notification-type";

@Injectable({
    providedIn: 'root'
})
export class PushNotificationsService {
    private readonly FCM_SENDERID = '363599767919';
    private readonly FCM_SERVER = 'https://fcm.googleapis.com/fcm/send';
    private readonly SERVER_KEY = 'AAAAVKg8KW8:APA91bFIee9zPOqyw-398mfYJEqAU2siCjfAfs_RW0v5Qy2IV4X79039CP8_nEuCH4X2lbEdBsneHIv4wQPGRhBJNwQjK-nBTiQVx6ejNiByiXp20zJ3U1iyjLrzgwQC5f85_ec3eB3d';

    constructor(
        private badge: Badge,
        private http: HttpClient,
        private platform: Platform,
        private db: DatabaseService,
        private alerts: AlertsService,
        private fcm: FirebaseMessaging,
        private decimalPipe: DecimalPipe,
        private localNotifications: LocalNotifications,
        private localStorageService: LocalStorageService
    ) { }

    init(): void {
        if (this.isMobileDevice()) {
            this.localNotifications.hasPermission().then(granted => {
                if (!granted) {
                    this.localNotifications.requestPermission();
                }
            });
    
            this.badge.hasPermission().then(granted => {
                if (!granted) {
                    this.badge.requestPermission();
                }
            });
            
            // if (this.fcm) {
            //     this.fcm.requestPermission();
            //     this.fcm.onTokenRefresh().pipe(
            //         switchMap(() => this.fcm.getToken()),
            //         filter(token => !!token),
            //         tap(token => this.db.registerFcmToken(token))
            //     ).subscribe();
        
            //     from(this.fcm.getToken()).pipe(
            //         first(),
            //         filter(token => !!token),
            //         tap(token => this.db.registerFcmToken(token))
            //     ).subscribe();
            // }
        }
    }

    sendPushNotification(type: PushNotificationType): Observable<any> {
        return this.db.getAllFCMTokens().pipe(
            map(tokens => tokens.filter(Boolean)),
            switchMap(tokens => this.sendFCM(tokens, type))
        );
    }

    clearBadge(): void {
        this.badge.hasPermission().then((granted) => {
            if (granted) {
                this.badge.clear();
            }
            else {
                this.badge.requestPermission().then((granted) => {
                    if (granted) {
                        this.badge.clear();
                    }
                }, error => this.alerts.error(error));
            }
        }, error => this.alerts.error(error));
    }

    private sendFCM(tokens: string[], type: PushNotificationType): Observable<any> {
        let arr = [];
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `key=${this.SERVER_KEY}`
        });

        switch (type) {
            case PushNotificationType.BALANCE_UPDATE:
                let balance = this.localStorageService.get(LocalStorageKey.MONTHLY_BALANCE);
                balance = balance ? this.decimalPipe.transform(balance) : 0;
                
                for (let i = 0; i < tokens?.length; i++) {
                    arr.push(this.http.post(this.FCM_SERVER, {
                        to: tokens[i],
                        notification: {
                            title: 'יתרת העובר ושב התעדכנה',
                            body: `יתרה חודשית פנויה לניצול: ${balance} ש״ח`,
                            sound: "default",
                            click_action: "FCM_PLUGIN_ACTIVITY",
                            icon: 'fcm_push_icon',
                        },
                        data: {
                            title: 'יתרת העובר ושב התעדכנה',
                            body: `יתרה חודשית פנויה לניצול: ${balance} ש״ח`,
                            fcm_from: this.FCM_SENDERID
                        },
                        contentAvailable: 1,
                        priority: "high"
                    }, { headers }));
                }
                break;
            
            case PushNotificationType.SAVINGS_UPDATE:
                let savings = this.localStorageService.get(LocalStorageKey.TOTAL_SAVINGS);
                savings = savings ? this.decimalPipe.transform(savings) : 0;
                    
                for (let i = 0; i < tokens?.length; i++) {
                    arr.push(this.http.post(this.FCM_SERVER, {
                        to: tokens[i],
                        notification: {
                            title: 'יתרת החסכונות התעדכנה',
                            body: `סה״כ חסכונות: ${savings} ש״ח`,
                            sound: "default",
                            click_action: "FCM_PLUGIN_ACTIVITY",
                            icon: 'fcm_push_icon',
                        },
                        data: {
                            title: 'יתרת החסכונות התעדכנה',
                            body: `סה״כ חסכונות: ${savings} ש״ח`,
                            fcm_from: this.FCM_SENDERID
                        },
                        contentAvailable: 1,
                        priority: "high"
                    }, { headers }));
                }
                break;
        }

        return forkJoin(arr).pipe(
            tap(() => {
                if (this.isMobileDevice()) {
                    this.sendLocalNotification(type);
                    this.badge.set(1);
                }
            })
        );
    }

    private sendLocalNotification(type: PushNotificationType): void {
        let notification;
        switch (type) {
            case PushNotificationType.BALANCE_UPDATE:
                let balance = this.localStorageService.get(LocalStorageKey.MONTHLY_BALANCE);
                balance = balance ? this.decimalPipe.transform(balance) : 0;

                notification = {
                    icon: 'assets/logo.png',
                    title: 'יתרת העובר ושב התעדכנה',
                    text: `יתרה חודשית פנויה לניצול: ${balance} ש״ח`,
                    foreground: true,
                    led: { color: '#FF00FF', on: 500, off: 500 },
                    vibrate: true,
                    launch: true,
                    wakeup: true,
                    badge: 1,
                };
                break;
            
            case PushNotificationType.SAVINGS_UPDATE:
                let savings = this.localStorageService.get(LocalStorageKey.TOTAL_SAVINGS);
                savings = savings ? this.decimalPipe.transform(savings) : 0;

                notification = {
                    icon: 'assets/logo.png',
                    title: 'יתרת החסכונות התעדכנה',
                    text: `סה״כ חסכונות: ${savings} ש״ח`,
                    foreground: true,
                    led: { color: '#FF00FF', on: 500, off: 500 },
                    vibrate: true,
                    launch: true,
                    wakeup: true,
                    badge: 1,
                };
                break;
        }

        this.localNotifications.schedule(notification);
    }

    private isMobileDevice(): boolean {
        return !this.platform.is('desktop') && !document.URL.startsWith('http');
    }
}