import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Platform } from '@ionic/angular';

import { AlertsService } from 'src/app/core/alerts.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  formGroup: FormGroup;

  loginTpl: TemplateRef<any>;

  @ViewChild('faceIdLogin')
  faceIdLoginTpl: TemplateRef<any>;

  @ViewChild('usernamePasswordLogin')
  usernamePasswordLoginTpl: TemplateRef<any>;

  constructor(
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private faio: FingerprintAIO,
    private platform: Platform
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngAfterViewInit(): void {
    this.loginTpl = this.isMobileDevice() ? this.faceIdLoginTpl : this.usernamePasswordLoginTpl;

    // auto login with faceid
    if (this.isMobileDevice()) {
      this.platform.ready().then(() => {
        setTimeout(() => {
          this.faceId();
        }, 1000);
      });
    }
  }

  switchTpl(): void {
    this.loginTpl = this.loginTpl === this.faceIdLoginTpl ? this.usernamePasswordLoginTpl : this.faceIdLoginTpl;
  }

  faceId(): void {
    this.faio.isAvailable()
      .then(
        res => {
          this.faio.loadBiometricSecret({
            description: 'הזדהות באמצעות זיהוי פנים'
          }).then((secret) => {
            if (!secret) {
              this.alertService.error('תהליך הזיהוי נכשל, יש להרשם להתחברות באמצעות זיהוי פנים לאחר ההתחברות');
            }
            else {
              this.authService.loginWithToken(secret);
            }
          }, error => {
            this.alertService.error('תהליך הזיהוי נכשל, יש להרשם להתחברות באמצעות זיהוי פנים לאחר ההתחברות');
            this.loginTpl = this.usernamePasswordLoginTpl;
          });
        },
        error => {
          this.alertService.error('לא ניתן להתחבר באמצעות זיהוי פנים')
            .then(() => this.loginTpl = this.usernamePasswordLoginTpl);
        }
      );
  }

  submit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.authService.login(
      this.formGroup.get("username").value,
      this.formGroup.get("password").value
    );
  }

  isMobileDevice(): boolean {
    return !this.platform.is('desktop') && !document.URL.startsWith('http');
  }
}