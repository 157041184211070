import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ChartsModule } from 'ng2-charts';

import { SkeletonModule } from './skeleton.module';
import { CustomMaterialModule } from './material.module';
import { GlassmorphismCardComponent } from './glassmorphism-card/glassmorphism-card.component';
import { PeriodicChargeTableComponent } from './periodic-charge-table/periodic-charge-table.component';
import { DepositOverviewTableComponent } from './deposit-overview-table/deposit-overview-table.component';

@NgModule({
    imports: [
        CommonModule,
        ChartsModule,
        SkeletonModule
    ],
    exports: [
        CommonModule,
        IonicModule,
        CustomMaterialModule,
        ReactiveFormsModule,
        GlassmorphismCardComponent,
        PeriodicChargeTableComponent,
        DepositOverviewTableComponent
    ],
    declarations: [
        GlassmorphismCardComponent,
        PeriodicChargeTableComponent,
        DepositOverviewTableComponent
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class CoreModule { }
