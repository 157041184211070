import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { CustomMaterialModule } from './material.module';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        CommonModule,
        IonicModule,
        CustomMaterialModule,
        ReactiveFormsModule,
        SkeletonLoaderComponent
    ],
    declarations: [
        SkeletonLoaderComponent
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SkeletonModule { }
