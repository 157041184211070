export class Deposit {
    depositId: string;
    depositAmount: number;
    currency?: string;
    route?: string;
    order: number;
    provider: string;
    hideChart?: boolean;
    cashable?: boolean;
    cashableDate?: Date | string;
    managementDepositFee?: number;
    managementSavingsFee?: number;
    excludeFromTotalSavings?: boolean;
    excludeFromPensionTotal?: boolean;
}