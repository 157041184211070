import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { AngularFireAuth } from "@angular/fire/auth";

import { RoutesManager } from '../app-routing.module';
import { AlertsService } from '../core/alerts.service';
import { PushNotificationsService } from '../core/fcm.service';
import { LocalStorageKey, LocalStorageService } from '../core/local-storage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private pushNotificationsService: PushNotificationsService,
    private localStorageService: LocalStorageService,
    private navCtrl: NavController,
    private afAuth: AngularFireAuth,
    private alerts: AlertsService,
    private platform: Platform
  ) { }

  login(email: string, password: string): void {
    this.afAuth.signInWithEmailAndPassword(email, password).then((user) => {
      this.localStorageService.store(LocalStorageKey.USER_TOKEN, `${btoa(email)}-${btoa(password)}`);
      this.localStorageService.store(LocalStorageKey.USER_ID, user.user.uid);
      this.localStorageService.store(LocalStorageKey.USER_EMAIL, user.user.email);
      
      this.navCtrl.navigateBack([RoutesManager.app]).then(() => {
        if (this.isMobileDevice()) {
          this.pushNotificationsService.clearBadge();
        }
      });
    }).catch(error => {
      console.log(error);
      this.alerts.error(error);
    });
  }

  loginWithToken(token: string): void {
    if (!token) {
      this.alerts.error('תהליך הזיהוי נכשל, יש להרשם להתחברות באמצעות זיהוי פנים לאחר ההתחברות');
    }
    else {
      const res = token.split('-');
      this.login(atob(res[0]), atob(res[1]));
    }
  }

  logout(error?: any): void {
    if (error) {
      this.alerts.error(error);
    }

    this.afAuth.signOut().then(() => {
      this.localStorageService.clear();
      this.navCtrl.navigateBack([RoutesManager.login]).then(() => {
        if (this.isMobileDevice()) {
          this.pushNotificationsService.clearBadge();
        }
      });
    });
  }

  private isMobileDevice(): boolean {
    return !this.platform.is('desktop') && !document.URL.startsWith('http');
  }
}