import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';

export class RoutesManager {
    static readonly app: string = "app";
    static readonly login: string = "login";
    static readonly logout: string = "logout";
    static readonly tabs: string = "tabs";
    static readonly overview: string = "overview";
    static readonly balance: string = "balance";
    static readonly pension: string = "pension";
    static readonly paystub: string = "paystub";
    static readonly profile: string = "profile";
    static readonly addDeposit: string = "add-deposit";
    static readonly updateBalance: string = "update-balance";
    static readonly addFixedCharge: string = "add-fixed-charge";
}

const routes: Routes = [
  {
    path: '',
    redirectTo: `${RoutesManager.app}/${RoutesManager.tabs}/${RoutesManager.overview}`,
    pathMatch: 'full'
  },
  {
    path: RoutesManager.login,
    component: LoginComponent
  },
  {
    path: RoutesManager.logout,
    component: LogoutComponent
  },
  {
    path: RoutesManager.app,
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(private router: Router, private authService: AuthService) {
    this.router.errorHandler = (error: any) => {
      this.authService.logout(error);
    }
  }
}