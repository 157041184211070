import { Injectable } from '@angular/core';

export enum LocalStorageKey {
  USER = 'user',
  USER_ID = 'userId',
  USER_EMAIL = 'userEmail',
  USER_TOKEN = 'userToken',
  TOTAL_SAVINGS = 'totalSavings',
  MONTHLY_BALANCE = 'monthlyBalance',
  START_OF_MONTH_BALANCE = 'startOfMonthBalance'
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

  public store(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
    
  public get(key: string): any {
    return localStorage.getItem(key) ?
      JSON.parse(localStorage.getItem(key)) : '';
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }
}